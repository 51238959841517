.overrideexpandbutton 
    .anticon, .anticon-caret-down, .ant-tree-switcher-icon
    {
        font-size: 20px !important; // I tried avoiding !important so hard
        color: #1890ff;
    }


.tree-helptext-outer {
    display: flex;
    font-size: 0.8em;
    align-items: center;
}